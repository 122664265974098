const state = {
  pretendUsersOptions: [],
};

const mutations = {
  SET_PRETEND_USERS_OPTIONS(state, options) {
    state.pretendUsersOptions = options;
  },
};

const actions = {
  async fetchPretendUsersOptions({ commit }) {
    try {
      const res = await this.$_api.PRETEND_USER_SEARCH({
        pageNum: 1,
        pageSize: 1000,
      });
      if (res.data?.code === 1) {
        const options = res.data?.data?.list.map((item) => {
          return {
            label: item.nickName,
            value: item.userId,
          };
        });
        commit("SET_PRETEND_USERS_OPTIONS", options);
      }
    } catch (error) {
      console.error("获取马甲用户选项失败", error);
    }
  },
};

const getters = {
  pretendUsersOptions: (state) => state.pretendUsersOptions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
